import React from 'react';
import { Navbar, Nav, NavDropdown, Container, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
//import 'bootswatch/dist/coder/bootstrap.min.css'; // CoderPro 템플릿 CSS 추가

function CustomNavbar() {
    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="mb-5">
        <Container fluid>
            <Navbar.Brand as={Link} to="/" title="세상의 모든 계산기">
                <FontAwesomeIcon icon={faCoffee} size="2x" />
            </Navbar.Brand>
    
            <Navbar.Toggle aria-controls="navbarNav" />
    
            <Navbar.Collapse id="navbarNav">
                <Nav className="mr-auto">
                    {/* 숨겨진 드롭다운 메뉴. 필요에 따라 항목을 추가하십시오. 
                    <Button as={NavLink} variant="secondary" size="sm" className="m-1" to="/calculator">
                        일반
                    </Button>*/}
                    <Button as={NavLink} variant="secondary" size="sm" className="m-1" to="/lotto">
                        로또생성기
                    </Button>
                    <Button as={NavLink} variant="secondary" size="sm" className="m-1" to="/ecalculator">
                        공학
                    </Button>
                    <Button as={NavLink} variant="secondary" size="sm" className="m-1" to="/age-calculator">
                        나이
                    </Button>
                    <Button as={NavLink} variant="secondary" size="sm" className="m-1" to="/interest-calculator">
                        이자율
                    </Button>
                    <Button as={NavLink} variant="secondary" size="sm" className="m-1" to="/per-calculator">
                        퍼센트
                    </Button>
                    <Button as={NavLink} variant="secondary" size="sm" className="m-1" to="/p-Formatter">
                        코드정렬
                    </Button>
                    <Button as={NavLink} variant="secondary" size="sm" className="m-1" to="/weather">
                        세계날씨
                    </Button>
    
                    {/* 숨겨진 드롭다운 메뉴. 필요에 따라 항목을 추가하십시오. */}
                    <NavDropdown title="계산기" id="navbarDropdown" className="d-none">
                        {/* 다른 서브 메뉴 항목도 여기에 추가 가능 */}
                    </NavDropdown>
    
                    {/* 다른 메뉴 항목도 여기에 추가 가능 */}
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    
    );
}

export default CustomNavbar;
